import request from '@/libs/request';

export const GetHcoTotalAllProvince = data =>
  request('MdmAdmin/DataStatisticsService/GetHcoTotalAllProvince', data);
export const GetHcoTotalByProvince = data =>
  request('MdmAdmin/DataStatisticsService/GetHcoTotalByProvince', data);
export const GetHcoTotalByCity = data =>
  request('MdmAdmin/DataStatisticsService/GetHcoTotalByCity', data);
export const GetHcoData = data => request('MdmAdmin/DataStatisticsService/GetHcoData', data);
export const SearchImportantData = data =>
  request('MdmAdmin/DataStatisticsService/SearchImportantData', data);

export const GetJurisdictionHcoCounts = data =>
  request('MdmAdmin/DataStatisticsService/GetJurisdictionHcoCounts', data);
