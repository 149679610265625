import hospital from './hospital.svg';
import dealer from './dealer.svg';
import instruments from './instruments.svg';
import jiHospital from './jiHospital.svg';
import manufacturer from './manufacturer.svg';
import other from './other.svg';
import otherHospital from './otherHospital.svg';
import shop from './shop.svg';
import zHospital from './zHospital.svg';

export default {
  hospital,
  dealer,
  instruments,
  jiHospital,
  manufacturer,
  other,
  otherHospital,
  shop,
  zHospital,
};
