<!--  -->
<template>
  <div class="container">
    <div class="container-top">
      <div class="search">
        <div class="title">机构地图</div>
        <div class="inp">
          <el-cascader
            class="inputs"
            :props="orgTypes"
            v-model="orgType"
            placeholder="机构类型"
            clearable
          ></el-cascader>
          <el-cascader
            clearable
            v-if="isShow"
            ref="cascader"
            placeholder="行政区划"
            class="inputs"
            :props="addressProps"
            v-model="address"
          ></el-cascader>
          <el-select class="inputs" v-model="hospitalLevel" placeholder="医院级别" clearable>
            <el-option
              v-for="(item, index) in hospitalLevelOption"
              :key="index"
              :label="item.label"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-select class="inputs" v-model="businessStatus" placeholder="经营状态" clearable>
            <el-option
              v-for="(item, index) in businessStatusOption"
              :key="index"
              :label="item.label"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-input
            class="inputs-name"
            placeholder="请输入机构名称、别名或曾用名"
            v-model="name"
          ></el-input>
          <el-button type="primary" @click="handleSearch()" class="btn">查询</el-button>
        </div>
      </div>
    </div>
    <div class="container-bottom">
      <div class="maps" v-loading="loading">
        <div id="map1" v-if="map1Show"></div>
        <div id="map2" v-if="map2Show"></div>
        <div id="map3" v-if="map3Show" v-loading="mapLoading"></div>
        <div id="map" v-if="mapShow" v-loading="mapLoading"></div>

        <div class="cover-select" v-if="mapShow">
          <div
            v-for="(item, i) in coverSelect"
            :key="item.value"
            :class="{ 'item-active': selectIndex === i }"
            @click="handleSelectCover(i)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="example" v-if="mapShow">
          <div v-for="(item, index) in example" :key="index" class="item">
            <div class="dots">
              <img :src="iconList[item.icon]" alt="" />
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="container-right" v-loading="loading">
        <div class="container-right-top">
          <div class="total">
            <div class="num">
              <span>{{ areaName }}：</span>
              <!-- <span v-else>{{ address[address.length - 1].split(',')[1] }}：</span> -->
              <span>{{ parseFloat(totalNum).toLocaleString() }}</span>
              <span>家</span>
            </div>
            <div class="coverage">
              <div class="left">
                <div class="cover">已覆盖</div>
                <span>{{ parseFloat(orgCover).toLocaleString() }}</span>
              </div>
              <div class="right">
                <div class="notcover">未覆盖</div>
                <span>{{ parseFloat(orgNotCover).toLocaleString() }}</span>
              </div>
            </div>
          </div>
          <div class="result">
            <div class="result-num">查询到{{ data.length }}条结果</div>
            <div class="cover-btn" v-if="map1Show || map2Show || map3Show">
              <div class="cover-text">覆盖率</div>
              <div class="btn">
                <div
                  :class="sortActive === 'top' ? 'top-active' : 'top'"
                  @click="handleSort('top')"
                ></div>
                <div
                  :class="sortActive === 'down' ? 'down' : 'down-notActive'"
                  @click="handleSort('down')"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-right-list">
          <div class="container-right-list-item" v-for="(item, index) in data" :key="index">
            <div v-if="!item.name">
              <p class="city-name" v-if="!item.city_name">{{ item.province_name }}</p>
              <p class="city-name" v-else-if="!item.district_name">{{ item.city_name }}</p>
              <p class="city-name" v-else>{{ item.district_name }}</p>
              <div class="org-num">
                <div class="left">
                  <span>全部机构：</span>
                  <span>{{ parseFloat(item.total).toLocaleString() }}</span>
                </div>
                <div class="right">
                  <span>覆盖率：</span>
                  <span>{{ (item.coverage * 100).toFixed(2) }}%</span>
                </div>
              </div>
              <div class="coverage">
                <div class="left">
                  <div class="cover">已覆盖</div>
                  <span>{{ parseFloat(item.cover).toLocaleString() }}</span>
                </div>
                <div class="right">
                  <div class="notcover">未覆盖</div>
                  <span>{{ parseFloat(item.notCover).toLocaleString() }}</span>
                </div>
              </div>
            </div>
            <div v-if="item.name" class="area-hcp">
              <p class="city-name1" @click="handleGoDetail(item)">{{ item.name }}</p>
              <div class="area-hcp-content">
                <div class="org-type">
                  <div class="hcp-type1">
                    <i
                      class="tag"
                      :style="{ background: orgColorOptions[item.hco_type_name2] }"
                    ></i>
                    <p>{{ item.hco_type_name2 }}</p>
                  </div>
                  <div class="hcp-type2">
                    {{ item.hco_type_name3 }}
                  </div>
                  <div class="is-cover">
                    {{ item.covered === 1 ? '已覆盖' : '未覆盖' }}
                  </div>
                </div>
                <div class="status">
                  经营状态：{{ item.business_status | format(businessStatusOption) }}
                </div>
                <div class="hcp-address">
                  {{ item.address }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetOrgTypeList,
  GetAdminDistrict,
  GetDictOptionList,
  SearchMainDataByType,
} from '@/api/data';
import {
  GetHcoTotalAllProvince,
  GetHcoTotalByProvince,
  GetHcoTotalByCity,
  GetHcoData,
  SearchImportantData,
  GetJurisdictionHcoCounts,
} from '@/api/org-map';
import iconList from './iconList';
import _ from 'lodash';
export default {
  filters: {
    format(val, options = []) {
      for (const item of options) {
        if (Number(item.code) === Number(val) || item.code === val) {
          return item.label;
        }
      }
      return '无';
    },
  },
  data() {
    return {
      orgCover: 0,
      orgNotCover: 0,
      sortActive: 'down',
      coverSelect: [
        { label: '全部机构', value: 0 },
        { label: '未覆盖', value: 2 },
        { label: '已覆盖', value: 1 },
      ],
      selectIndex: 0,
      resquestCover: 0, // 查看区数据覆盖率筛选入参

      areaName: '',
      example: [
        { name: '医院', icon: 'hospital' },
        { name: '经销商', icon: 'dealer' },
        { name: '基层医疗卫生机构', icon: 'jiHospital' },
        { name: '专业公共卫生机构', icon: 'zHospital' },
        { name: '其他医疗卫生机构', icon: 'otherHospital' },
        { name: '药店', icon: 'shop' },
        { name: '医疗器械', icon: 'instruments' },
        { name: '生产企业', icon: 'manufacturer' },
        { name: '其他', icon: 'other' },
      ],
      mapLoading: false,
      addressProps: {
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          const nodes = [];
          const req = {
            code: level === 0 ? '' : node.value.split(',')[0],
            level: level.toString(),
          };
          if (level > 2) {
            return;
          }
          GetAdminDistrict(req).then(res => {
            res.district_list.map(item => {
              const obj = {
                value: `${item.code},${item.name}`,
                label: item.name,
                leaf: level === 2,
              };
              nodes.push(obj);
            });
            // resolve 节点返回
            resolve(nodes);
          });
        },
      },
      name: '',
      orgType: [],
      address: [],
      hospitalLevel: '',
      hospitalLevelOption: [],
      businessStatus: '',
      businessStatusOption: [],

      color: {},
      totalNum: 0,
      loading: false,
      data: [],

      orgColorOptions: {
        医院: '#00B33D',
        经销商: '#9E20FF',
        基层医疗卫生机构: '#FA9E00',
        专业公共卫生机构: '#E64545',
        其他医疗卫生机构: '#475AFF',
        药店: '#00C1E9',
        医疗器械: '#DA44C2',
        生产企业: '#7DC40A',
        其他: '#667082',
      },
      iconList,

      isShow: true,

      map1Show: true,
      map2Show: false,
      map3Show: false,
      mapShow: false,

      timer: null,
      importantData: [],
    };
  },
  computed: {
    orgTypes() {
      return {
        lazy: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          const nodes = [];
          const req = {
            parend_ids: level === 0 ? [] : [node.value],
            level: level + 2,
          };
          if (level > 3) {
            return;
          }
          GetOrgTypeList(req).then(res => {
            res.datas.map(item => {
              const obj = {
                value: item.type_id,
                label: item.type_name,
                leaf: !item.is_leaf,
              };
              nodes.push(obj);
            });
            resolve(nodes);
          });
        },
      };
    },
  },
  created() {
    SearchImportantData({}).then(res => {
      this.importantData = res.data.map(item => {
        return {
          district_code: item.district_code,
          district_name1: item.district_name,
          district_level: item.district_level,
          bed_number: item.bed_number,
          gdp: item.gdp,
          old_man: item.old_man,
          bed_utilize: item.bed_utilize,
          consumption: item.consumption,
          health_products: item.health_products,
          total_population: item.total_population,
        };
      });
    });
  },
  async mounted() {
    this.getHospitalLevel();
    await this.getAll();
    this.handleMap(
      'https://apis.map.qq.com/ws/district/v1/getchildren?key=KXMBZ-7O3WW-UVYRL-RNUFA-OWYYS-V5BN7&output=jsonp&get_polygon=2&max_offset=3000',
    );
  },
  methods: {
    // 区数据覆盖率筛选查看
    handleSelectCover(i) {
      if (i !== this.selectIndex) {
        this.selectIndex = i;
        this.resquestCover = this.coverSelect[i].value;
        this.handleSearch();
      }
    },
    // 覆盖率排序
    handleSort(val) {
      if (val === 'top') {
        this.sortActive = 'top';
        this.data = this.data.sort(function(a, b) {
          return a.coverage - b.coverage;
        });
      } else {
        this.sortActive = 'down';
        this.data = this.data.sort(function(a, b) {
          return b.coverage - a.coverage;
        });
      }
    },
    // 加载地图
    handleMap(
      url = 'https://apis.map.qq.com/ws/district/v1/getchildren?key=KXMBZ-7O3WW-UVYRL-RNUFA-OWYYS-V5BN7&output=jsonp&get_polygon=2&max_offset=3000',
      zoom = 4,
      location = { lat: 34.26486, lng: 108.95424 },
      mapContainer = 'map1',
    ) {
      const map = new window.TMap.Map(mapContainer, {
        // boundary: new window.TMap.LatLngBounds(
        //   new window.TMap.LatLng(1, 67),
        //   new window.TMap.LatLng(63, 148),
        // ),
        center: new window.TMap.LatLng(location.lat, location.lng), // 设置地图中心坐标
        zoom, // 地图进行缩放
        mapStyleId: 'style1', // 个性化样式
        baseMap: {
          // 设置底图样式
          type: 'vector', // 设置底图为矢量底图
          features: [
            // 设置矢量底图要素类型
            'base',
            'point',
          ],
        },
        // disableDoubleClickZoom: true,
        // scrollwheel: false,
        // scrollable: false,
        doubleClickZoom: false,
      });
      window.$.ajax({
        url, // 请求地址+参数
        type: 'GET', // get请求
        dataType: 'JSONP', // 指定服务器返回的数据类型
        success: res => {
          console.log(res);
          // console.log(this.data);
          // const infoList = [];
          let arr = [];

          if (!this.address.length) {
            // const mapObj = {};
            // res.result[0].forEach((item) => {
            //   mapObj[item.fullname] = item;
            // });
            // const dataObj = {};
            // this.data.forEach((item) => {
            //   dataObj[item.province_name] = item;
            // });
            // Object.keys(mapObj).forEach((key) => {
            //   console.log(key);
            //   let total = 0;
            //   if (dataObj[key]) {
            //     total = dataObj[key].total;
            //   }
            //   arr.push({
            //     ...mapObj[key],
            //     total,
            //   });
            // });
            const mapObj = new Map();
            this.data.forEach(item => {
              mapObj.set(item.province_name, item);
            });
            arr = res.result[0].map(item => {
              if (mapObj.has(item.fullname)) {
                return { ...item, ...mapObj.get(item.fullname) };
              }
              return { ...item, total: 0 };
            });
          }

          if (this.address.length === 1) {
            // const mapObj = {};
            // res.result[0].forEach((item) => {
            //   mapObj[item.fullname] = item;
            // });
            // const dataObj = {};
            // this.data.forEach((item) => {
            //   if (item.city_name) {
            //     dataObj[item.city_name] = item;
            //   }
            //   if (item.district_name) {
            //     dataObj[item.district_name] = item;
            //   }
            // });
            // Object.keys(mapObj).forEach((key) => {
            //   let total = 0;
            //   if (dataObj[key]) {
            //     total = dataObj[key].total;
            //   }
            //   arr.push({
            //     ...mapObj[key],
            //     total,
            //   });
            // });
            const mapObj = new Map();
            this.data.forEach(item => {
              if (item.city_name) {
                mapObj.set(item.city_name, item);
              }
              if (item.district_name) {
                mapObj.set(item.district_name, item);
              }
            });
            arr = res.result[0].map(item => {
              if (mapObj.has(item.fullname)) {
                return { ...item, ...mapObj.get(item.fullname) };
              }
              return { ...item, total: 0 };
            });
          }

          if (this.address.length === 2) {
            // const mapObj = {};
            // res.result[0].forEach((item) => {
            //   mapObj[item.fullname] = item;
            // });
            // const dataObj = {};
            // this.data.forEach((item) => {
            //   dataObj[item.district_name] = item;
            // });
            // Object.keys(mapObj).forEach((key) => {
            //   let total = 0;
            //   if (dataObj[key]) {
            //     total = dataObj[key].total;
            //   }
            //   arr.push({
            //     ...mapObj[key],
            //     total,
            //   });
            // });
            const mapObj = new Map();
            this.data.forEach(item => {
              mapObj.set(item.district_name, item);
            });
            arr = res.result[0].map(item => {
              if (mapObj.has(item.fullname)) {
                return { ...item, ...mapObj.get(item.fullname) };
              }
              return { ...item, total: 0 };
            });
          }
          console.log('新方法', arr);
          // arr.forEach((item) => {
          //   this.totalNum += item.total;
          // });

          const sortArr = arr.sort(function(a, b) {
            return b.total - a.total;
          });
          // console.log('排序后的', sortArr);
          for (let i = 0; i < sortArr.length; i++) {
            if ((sortArr.length - i) / sortArr.length >= 0.9) {
              this.color[sortArr[i].id] = 'styel6';
            } else if (
              (sortArr.length - i) / sortArr.length >= 0.7 &&
              (sortArr.length - i) / sortArr.length < 0.9
            ) {
              this.color[sortArr[i].id] = 'styel5';
            } else if (
              (sortArr.length - i) / sortArr.length >= 0.5 &&
              (sortArr.length - i) / sortArr.length < 0.7
            ) {
              this.color[sortArr[i].id] = 'styel4';
            } else if (
              (sortArr.length - i) / sortArr.length >= 0.3 &&
              (sortArr.length - i) / sortArr.length < 0.5
            ) {
              this.color[sortArr[i].id] = 'styel3';
            } else if (
              (sortArr.length - i) / sortArr.length >= 0.1 &&
              (sortArr.length - i) / sortArr.length < 0.3
            ) {
              this.color[sortArr[i].id] = 'styel2';
            } else {
              this.color[sortArr[i].id] = 'styel1';
            }
          }
          // this.totalNum = parseFloat(this.totalNum).toLocaleString();
          // console.log('颜色', this.color);

          // console.log('地图渲染数据', arr);

          const paths = [];
          arr.forEach(item => {
            const styleId = this.color[Number(item.id)]; // 数据所有的id值
            const path = []; // TODO
            item.polygon?.forEach(p => {
              path.push([p.reverse()]);
            }); // 进行纬经度进行调换位置
            paths.push({
              path, // 经纬度点串
              styleId, // id=styleId
              name: item.fullname,
              total: item.total,
              district_id: item.district_id,
              district_code: item.district_code,
              district_name: item.district_name,
              district_level: item.district_level,
              bed_number: item.bed_number,
              gdp: item.gdp,
              old_man: item.old_man,
              bed_utilize: item.bed_utilize,
              consumption: item.consumption,
              health_products: item.health_products,
              total_population: item.total_population,
              total_hcp: item.total_hcp,
              cover: item.cover,
              notCover: item.notCover,
              coverage: item.coverage,
            });
          });
          const area = new window.TMap.visualization.Area({
            styles: {
              // 设置区域图样式
              styel1: {
                fillColor: '#E1EDFA', // 设置区域颜色
                strokeColor: '#E1EDFA', // 设置区域边线颜色
              },
              styel2: {
                fillColor: '#C8E0FA', // 设置区域颜色
                strokeColor: '#E1EDFA', // 设置区域边线颜色
              },
              styel3: {
                fillColor: '#96C3FA', // 设置区域颜色
                strokeColor: '#E1EDFA', // 设置区域边线颜色
              },
              styel4: {
                fillColor: '#3286FA', // 设置区域颜色
                strokeColor: '#E1EDFA', // 设置区域边线颜色
              },
              styel5: {
                fillColor: '#0069FA', // 设置区域颜色
                strokeColor: '#E1EDFA', // 设置区域边线颜色
              },
              styel6: {
                fillColor: '#0054C7', // 设置区域颜色
                strokeColor: '#E1EDFA', // 设置区域边线颜色
              },
            },
            selectOptions: {
              action: 'hover',
              style: {
                strokeColor: '#3286FA',
                strokeWidth: 1, // 区域边线宽度
              },
            },
          })
            .setData(paths)
            .addTo(map);

          let infoWindow = null;
          area.on('hover', e => {
            clearTimeout(this.timer);
            this.timer = null;
            this.timer = setTimeout(() => {
              // console.log(e);
              if (!e.detail.area) {
                return;
              }
              const { lat } = e.latLng;
              const { lng } = e.latLng;
              window.$.ajax({
                url: `https://apis.map.qq.com/ws/geocoder/v1/?location=${lat},${lng}&key=NONBZ-JCYC6-HL6S2-MQP6N-MSDV7-JDFOW&get_poi=0&output=jsonp`, // 请求地址+参数
                type: 'GET', // get请求
                dataType: 'JSONP', // 指定服务器返回的数据类型
                success: res2 => {
                  if (infoWindow) {
                    infoWindow.close();
                  }
                  // console.log('搜索', res2);
                  let provinceName = '';
                  if (!this.address.length) {
                    provinceName = res2.result.address_component.province;
                  }
                  if (this.address.length === 1) {
                    provinceName = res2.result.address_component.city;
                  }
                  if (
                    this.address.length === 1 &&
                    ['北京市', '上海市', '重庆市', '天津市'].includes(this.address[0].split(',')[1])
                  ) {
                    provinceName = res2.result.address_component.district;
                  }
                  if (this.address.length === 2) {
                    provinceName = res2.result.address_component.district;
                  }
                  // console.log('打印', provinceName);
                  if (!provinceName) return;
                  const provinceData = res.result[0].filter(item => item.fullname === provinceName);
                  const currentProvince = provinceData.length ? provinceData[0] : null;
                  if (provinceData.length === 0) return;
                  const infoWindowLocation = new window.TMap.LatLng(
                    currentProvince.location.lat,
                    currentProvince.location.lng,
                  ); // 创建一个坐标

                  // 查询HCP数量
                  if (e.detail.area.district_level) {
                    let hcpNum = 0;
                    const req = {
                      main_data: 't_hco_hcp',
                      data: '',
                      keyword: '',
                      index: 1,
                      page_size: 20,
                      search_fields: ['hco_province_code'],
                      SearchOptions: [
                        {
                          field_name: '',
                          data_name: 't_hco_hcp',
                          type: 2,
                          key: '',
                        },
                      ],
                      data_array: [],
                    };
                    if (e.detail.area.district_level === '1') {
                      (req.SearchOptions[0].field_name = 'hco_province_code'),
                        (req.SearchOptions[0].key = e.detail.area.district_code);
                    }
                    if (e.detail.area.district_level === '2') {
                      (req.SearchOptions[0].field_name = 'hco_city_code'),
                        (req.SearchOptions[0].key = e.detail.area.district_code);
                    }
                    SearchMainDataByType(req).then(res => {
                      // console.log('获取到的', res);
                      hcpNum = res.total_num;

                      infoWindow = new window.TMap.InfoWindow({
                        map,
                        position: infoWindowLocation,
                        // 设置infoWindow，content支持直接传入html代码，以实现各类内容格式需求
                        content: `<div style="width:312px;padding:0 16px">
                                        <div style="color:#0069FA;line-height:22px;font-size:14px;font-weight:500;margin-bottom:8px;text-align: left;">${provinceName}</div>
                                        <div style="display:flex;flex-wrap: wrap;padding-bottom:13px;border-bottom: 1px solid #E5EAF2;">
                                            <div style="width:50%;text-align: left;">
                                                <span style="font-size:14px;color:#667082;line-height: 22px;">全部机构：</span><span style="color: #1D2129;line-height: 22px;font-size: 14px;">${parseFloat(
                                                  e.detail.area.total,
                                                ).toLocaleString()}</span>
                                            </div>
                                            <div style="width:50%;text-align: left;">
                                                <span style="font-size:14px;color:#667082;line-height: 22px;">HCP：</span><span style="color: #1D2129;line-height: 22px;font-size: 14px;">${parseFloat(
                                                  hcpNum,
                                                ).toLocaleString()}</span>
                                            </div>

                                            <div style="display:flex;width:100%">
                                                <div style="display:flex;width:50%;align-items: center;">
                                                  <div style="width:50px;height:20px;background: #E1EEFA;border-radius: 2px;color: #1D2129;font-size:12px;font-weight:400;line-height:20px;margin-right:8px">已覆盖</div><span style="font-weight: 400;font-size: 14px;line-height: 22px;color: #1D2129;">${parseFloat(
                                                    e.detail.area.cover,
                                                  ).toLocaleString()}</span>
                                                </div>
                                                <div style="display:flex;width:50%;align-items: center;">
                                                  
                                                   <div style="width:50px;height:20px;background: #FAF1E1;border-radius: 2px;color: #1D2129;font-size:12px;font-weight:400;line-height:20px;margin-right:8px">未覆盖</div><span style="font-weight: 400;font-size: 14px;line-height: 22px;color: #1D2129;">${parseFloat(
                                                     e.detail.area.notCover,
                                                   ).toLocaleString()}</span>
                                                </div>
                                            </div>
                                     
                                        </div>
    
                                        <div style="display:flex;flex-direction: column;align-items: flex-start;margin-top:12px">
                                            <div >
                                                <span style="font-size:14px;color:#667082;line-height: 22px;">GDP(亿元)：</span><span style="color: #1D2129;line-height: 22px;font-size: 14px;">${parseFloat(
                                                  e.detail.area.gdp,
                                                ).toLocaleString()}</span>
                                            </div>
                                             <div style="margin-top:8px">
                                                <span style="font-size:14px;color:#667082;line-height: 22px;">社会消费品零售总额(亿元)：</span><span style="color: #1D2129;line-height: 22px;font-size: 14px;">${parseFloat(
                                                  e.detail.area.consumption,
                                                ).toLocaleString()}</span>
                                            </div>
                                             <div style="margin-top:8px">
                                                <span style="font-size:14px;color:#667082;line-height: 22px;">医疗保健类消费增长：</span><span style="color: #1D2129;line-height: 22px;font-size: 14px;">${
                                                  e.detail.area.health_products
                                                }%</span>
                                            </div>
                                            <div style="margin-top:8px">
                                                <span style="font-size:14px;color:#667082;line-height: 22px;">总人口(万)：</span><span style="color: #1D2129;line-height: 22px;font-size: 14px;">${
                                                  e.detail.area.total_population
                                                }</span>
                                            </div>
                                            <div style="margin-top:8px">
                                                <span style="font-size:14px;color:#667082;line-height: 22px;">65岁以上占比：</span><span style="color: #1D2129;line-height: 22px;font-size: 14px;">${
                                                  e.detail.area.old_man
                                                }%</span>
                                            </div>
                                              <div style="margin-top:8px">
                                                <span style="font-size:14px;color:#667082;line-height: 22px;">医疗卫生机构床位数(万张)：</span><span style="color: #1D2129;line-height: 22px;font-size: 14px;">${
                                                  e.detail.area.bed_number
                                                }</span>
                                            </div>
                                               <div style="margin-top:8px">
                                                <span style="font-size:14px;color:#667082;line-height: 22px;">医院病床使用率：</span><span style="color: #1D2129;line-height: 22px;font-size: 14px;">${
                                                  e.detail.area.bed_utilize
                                                }%</span>
                                            </div>
                                        </div>
                                    </div>`,
                      });
                    });
                  } else {
                    // 创建InfoWindow实例，并进行初始化
                    infoWindow = new window.TMap.InfoWindow({
                      map,
                      position: infoWindowLocation,
                      // 设置infoWindow，content支持直接传入html代码，以实现各类内容格式需求
                      content: `<div style="width:168px;">
                        <p style="color:#0069FA;line-height:22px;font-size:14px;font-weight:500;margin-bottom:12px">${provinceName}</p>
                         <div>
                          <span style="font-size:14px;color:#667082;line-height: 22px;">全部机构：</span><span style="font-family: DIN Next LT Pro;color: #1D2129;line-height: 22px;font-size: 14px;">${parseFloat(
                            e.detail.area.total,
                          ).toLocaleString()}</span>
                       </div>
                          <div style="display:flex;width:100%;margin-top:9px;">
                                                <div style="display:flex;width:50%;align-items: center;justify-content: center;">
                                                  <div style="width:50px;height:20px;background: #E1EEFA;border-radius: 2px;color: #1D2129;font-size:12px;font-weight:400;line-height:20px;margin-right:8px">已覆盖</div><span style="font-weight: 400;font-size: 14px;line-height: 22px;color: #1D2129;">${
                                                    e.detail.area.cover
                                                      ? parseFloat(
                                                          e.detail.area.cover,
                                                        ).toLocaleString()
                                                      : 0
                                                  }</span>
                                                </div>
                                                <div style="display:flex;width:50%;align-items: center;justify-content: center;">
                                                  
                                                   <div style="width:50px;height:20px;background: #FAF1E1;border-radius: 2px;color: #1D2129;font-size:12px;font-weight:400;line-height:20px;margin-right:8px">未覆盖</div><span style="font-weight: 400;font-size: 14px;line-height: 22px;color: #1D2129;">${
                                                     e.detail.area.notCover
                                                       ? parseFloat(
                                                           e.detail.area.notCover,
                                                         ).toLocaleString()
                                                       : 0
                                                   }</span>
                                                </div>
                                            </div>
                     </div>`,
                    });
                  }
                },
              });
            }, 1000);
          });
          area.on('click', e => {
            console.log(e.detail.area);
            if (!e.detail.area) {
              return;
            }
            this.data.forEach(value => {
              if (value.province_name === e.detail.area.name) {
                this.address = [`${value.province_code},${value.province_name}`];
                this.isShow = false;
                setTimeout(() => {
                  this.isShow = true;
                }, 100);
                this.handleSearch(value.province_name);
              }
              if (value.city_name === e.detail.area.name) {
                this.address = [
                  `${value.province_code},${value.province_name}`,
                  `${value.city_code},${value.city_name}`,
                ];
                this.isShow = false;
                setTimeout(() => {
                  this.isShow = true;
                }, 100);
                this.handleSearch(value.city_name);
              }
              if (value.district_name === e.detail.area.name) {
                this.address = [
                  `${value.province_code},${value.province_name}`,
                  `${value.city_code},${value.city_name}`,
                  `${value.district_code},${value.district_name}`,
                ];
                this.isShow = false;
                setTimeout(() => {
                  this.isShow = true;
                }, 100);
                this.handleSearch(value.district_name);
              }
            });
          });
        },
      });
    },
    // 获取默认全国省份数量
    async getAll() {
      this.loading = true;
      const req = {
        condition: {
          hco_type_name: '',
          hco_type_key: '',
          hospital_level: '',
          business_status: '',
          hco_name: '',
        },
      };
      const res = await GetHcoTotalAllProvince(req);
      let tmp = [];
      tmp = res.province_data;
      // // 按A-Z排序
      // tmp = tmp.sort(function(a, b) {
      //   return a.province_name.localeCompare(b.province_name, 'zh');
      // });
      const importantMap = new Map();
      this.importantData.forEach(item => {
        importantMap.set(item.district_name1, item);
      });
      this.data = tmp.map(item => {
        if (importantMap.has(item.province_name)) {
          return { ...item, ...importantMap.get(item.province_name) };
        }
        return item;
      });
      // this.data.forEach((item) => {
      //   this.totalNum += item.total;
      // });
      // console.log('此时的',this.data);
      this.areaName = '全部机构';

      const reqs = {
        districe_level: 1,
        district_code: '',
        conditions: {
          hco_type_name: '',
          hco_type_key: '',
          hospital_level: '',
          business_status: '',
          hco_name: '',
        },
      };
      const cover = await GetJurisdictionHcoCounts(reqs);
      // console.log('覆盖', cover);
      const coverMap = new Map();
      cover.data.forEach(item => {
        coverMap.set(item.code, item);
      });
      console.log('coverMap', coverMap);
      this.data = this.data.map(item => {
        if (coverMap.has(item.district_code)) {
          return {
            ...item,
            cover: coverMap.get(item.district_code).cnt,
            notCover: item.total - coverMap.get(item.district_code).cnt,
            coverage: item.total !== 0 ? coverMap.get(item.district_code).cnt / item.total : 0,
          };
        }
        return { ...item, cover: 0, notCover: item.total, coverage: 0 };
      });

      this.data = this.data.sort(function(a, b) {
        return b.coverage - a.coverage;
      });
      this.data.forEach(item => {
        this.totalNum += item.total;
        this.orgCover += item.cover;
        // this.orgNotCover += item.notCover;
      });
      this.orgNotCover = this.totalNum - this.orgCover;
      this.loading = false;
      console.log('合并后', this.data);
    },
    // 获取医院等次和经营状态
    getHospitalLevel() {
      GetDictOptionList({ dict_api_name: 'hospital_level', dict_type: '0' }).then(res => {
        this.hospitalLevelOption = res.dict_list;
      });
      GetDictOptionList({ dict_api_name: 'business_status', dict_type: '0' }).then(res => {
        this.businessStatusOption = res.dict_list;
      });
    },
    // 查询
    async handleSearch(name) {
      this.mapShow = false;
      this.map1Show = false;
      this.map2Show = false;
      this.map3Show = false;

      this.totalNum = 0;
      this.color = {};
      this.loading = true;
      const req = {
        condition: {
          hco_type_name: '',
          hco_type_key: '',
          hospital_level: this.hospitalLevel,
          business_status: this.businessStatus,
          hco_name: this.name,
        },
      };
      if (this.orgType.length) {
        req.condition.hco_type_name = `hco_type${this.orgType.length + 1}`;
        req.condition.hco_type_key = this.orgType[this.orgType.length - 1];
      }
      if (this.address.length === 0) {
        setTimeout(() => {
          this.map1Show = true;
        }, 100);
        const res = await GetHcoTotalAllProvince(req);
        let tmp = [];
        tmp = res.province_data;
        // // 按A-Z排序
        // tmp = tmp.sort(function (a, b) {
        //   return a.province_name.localeCompare(b.province_name, 'zh');
        // });

        const importantMap = new Map();
        this.importantData.forEach(item => {
          importantMap.set(item.district_name1, item);
        });
        this.data = tmp.map(item => {
          if (importantMap.has(item.province_name)) {
            return { ...item, ...importantMap.get(item.province_name) };
          }
          return item;
        });

        // this.data.forEach((item) => {
        //   this.totalNum += item.total;
        // });
        this.areaName = '全部机构';

        const reqs = {
          districe_level: 1,
          district_code: '',
          conditions: {
            hco_type_name: '',
            hco_type_key: '',
            hospital_level: this.hospitalLevel,
            business_status: this.businessStatus,
            hco_name: this.name,
          },
        };
        if (this.orgType.length) {
          reqs.conditions.hco_type_name = `hco_type${this.orgType.length + 1}`;
          reqs.conditions.hco_type_key = this.orgType[this.orgType.length - 1];
        }
        const cover = await GetJurisdictionHcoCounts(reqs);
        // console.log('覆盖', cover);
        const coverMap = new Map();
        cover.data.forEach(item => {
          coverMap.set(item.code, item);
        });
        console.log('coverMap', coverMap);
        this.data = this.data.map(item => {
          if (coverMap.has(item.district_code)) {
            return {
              ...item,
              cover: coverMap.get(item.district_code).cnt,
              notCover: item.total - coverMap.get(item.district_code).cnt,
              coverage: item.total !== 0 ? coverMap.get(item.district_code).cnt / item.total : 0,
            };
          }
          return { ...item, cover: 0, notCover: item.total, coverage: 0 };
        });

        this.data = this.data.sort(function(a, b) {
          return b.coverage - a.coverage;
        });
        this.orgCover = 0;
        this.orgNotCover = 0;
        this.totalNum = 0;
        this.data.forEach(item => {
          this.totalNum += item.total;
          this.orgCover += item.cover;
          // this.orgNotCover += item.notCover;
        });
        this.orgNotCover = this.totalNum - this.orgCover;
        console.log('合并后', this.data);
        this.loading = false;
        this.sortActive = 'down';
        this.handleMap();
      }
      if (this.address.length === 1) {
        req.province_code = `${this.address[0].split(',')[0]}`;
        const res = await GetHcoTotalByProvince(req);

        let tmp = [];
        tmp = res.city_data;
        // // 按A-Z排序
        // tmp = tmp.sort(function (a, b) {
        //   return a.city_name.localeCompare(b.city_name, 'zh');
        // });

        const importantMap = new Map();
        this.importantData.forEach(item => {
          importantMap.set(item.district_name1, item);
        });
        this.data = tmp.map(item => {
          if (importantMap.has(item.city_name)) {
            return { ...item, ...importantMap.get(item.city_name) };
          }
          return item;
        });

        // this.data.forEach((item) => {
        //   this.totalNum += item.total;
        // });
        const cityReq = {
          districe_level: 2,
          district_code: `${this.address[0].split(',')[0]}`,
          conditions: {
            hco_type_name: '',
            hco_type_key: '',
            hospital_level: this.hospitalLevel,
            business_status: this.businessStatus,
            hco_name: this.name,
          },
        };
        if (this.orgType.length) {
          cityReq.conditions.hco_type_name = `hco_type${this.orgType.length + 1}`;
          cityReq.conditions.hco_type_key = this.orgType[this.orgType.length - 1];
        }
        const cityCover = await GetJurisdictionHcoCounts(cityReq);
        console.log('市覆盖', cityCover);
        const cityCoverMap = new Map();
        cityCover.data.forEach(item => {
          cityCoverMap.set(item.code, item);
        });
        this.data = this.data.map(item => {
          if (cityCoverMap.has(item.district_code)) {
            return {
              ...item,
              cover: cityCoverMap.get(item.district_code).cnt,
              notCover: item.total - cityCoverMap.get(item.district_code).cnt,
              coverage:
                item.total !== 0 ? cityCoverMap.get(item.district_code).cnt / item.total : 0,
            };
          }
          return { ...item, cover: 0, notCover: item.total, coverage: 0 };
        });

        this.data = this.data.sort(function(a, b) {
          return b.coverage - a.coverage;
        });
        console.log('合并后', this.data);
        this.orgCover = 0;
        this.orgNotCover = 0;
        this.totalNum = 0;
        this.data.forEach(item => {
          this.totalNum += item.total;
          this.orgCover += item.cover;
        });
        this.orgNotCover = this.totalNum - this.orgCover;
        this.sortActive = 'down';
        this.loading = false;
        let keyWords = '';
        if (name) {
          keyWords = name;
        } else {
          keyWords = this.$refs.cascader.getCheckedNodes()[0].pathLabels[
            this.$refs.cascader.getCheckedNodes()[0].pathLabels.length - 1
          ];
        }

        console.log('传来的名字', keyWords);
        this.map1Show = false;
        this.map2Show = true;
        const url = `https://apis.map.qq.com/ws/district/v1/search?key=KXMBZ-7O3WW-UVYRL-RNUFA-OWYYS-V5BN7&output=jsonp&get_polygon=2&max_offset=3000&keyword=${keyWords}`;
        window.$.ajax({
          url,
          type: 'GET',
          dataType: 'JSONP',
          success: results => {
            if (['北京市', '上海市', '天津市', '重庆市'].includes(this.data[0]?.city_name)) {
              this.color = {};
              // this.totalNum = 0;
              req.province_code = this.data[0].province_code;
              req.city_code = this.data[0].city_code;
              GetHcoTotalByCity(req).then(res => {
                let tmp = [];
                tmp = res.district_data;
                // // 按A-Z排序
                // tmp = tmp.sort(function (a, b) {
                //   return a.district_name.localeCompare(b.district_name, 'zh');
                // });

                const importantMap = new Map();
                this.importantData.forEach(item => {
                  importantMap.set(item.district_name1, item);
                });
                this.data = tmp.map(item => {
                  if (importantMap.has(item.district_name)) {
                    return { ...item, ...importantMap.get(item.district_name) };
                  }
                  return item;
                });

                // this.data.forEach((item) => {
                //   this.totalNum += item.total;
                // });

                const cityReq = {
                  districe_level: 3,
                  district_code: this.data[0].city_code,
                  conditions: {
                    hco_type_name: '',
                    hco_type_key: '',
                    hospital_level: this.hospitalLevel,
                    business_status: this.businessStatus,
                    hco_name: this.name,
                  },
                };
                if (this.orgType.length) {
                  cityReq.conditions.hco_type_name = `hco_type${this.orgType.length + 1}`;
                  cityReq.conditions.hco_type_key = this.orgType[this.orgType.length - 1];
                }
                GetJurisdictionHcoCounts(cityReq).then(res => {
                  const cityCover = res;
                  console.log('区覆盖', cityCover);
                  const cityCoverMap = new Map();
                  cityCover.data.forEach(item => {
                    cityCoverMap.set(item.code, item);
                  });
                  this.data = this.data.map(item => {
                    if (cityCoverMap.has(item.district_code)) {
                      return {
                        ...item,
                        cover: cityCoverMap.get(item.district_code).cnt,
                        notCover: item.total - cityCoverMap.get(item.district_code).cnt,
                        coverage:
                          item.total !== 0
                            ? cityCoverMap.get(item.district_code).cnt / item.total
                            : 0,
                      };
                    }
                    return { ...item, cover: 0, notCover: item.total, coverage: 0 };
                  });

                  this.data = this.data.sort(function(a, b) {
                    return b.coverage - a.coverage;
                  });
                  console.log('合并后', this.data);
                  this.orgCover = 0;
                  this.orgNotCover = 0;
                  this.totalNum = 0;
                  this.data.forEach(item => {
                    this.totalNum += item.total;
                    this.orgCover += item.cover;
                  });
                  this.orgNotCover = this.totalNum - this.orgCover;
                  this.sortActive = 'down';

                  const mapUrl = `https://apis.map.qq.com/ws/district/v1/getchildren?key=KXMBZ-7O3WW-UVYRL-RNUFA-OWYYS-V5BN7&output=jsonp&get_polygon=2&max_offset=3000&id=${results.result[0][0].id}`;
                  const { location } = results.result[0][0];
                  this.handleMap(mapUrl, 9, location, 'map2');
                });

                // this.data = res.district_data;
                // // 按A-Z排序
                // this.data = this.data.sort(function (a, b) {
                //   return a.district_name.localeCompare(b.district_name, 'zh');
                // });
                // this.data.forEach((item) => {
                //   this.totalNum += item.total;
                // });
                // const mapUrl = `https://apis.map.qq.com/ws/district/v1/getchildren?key=KXMBZ-7O3WW-UVYRL-RNUFA-OWYYS-V5BN7&output=jsonp&get_polygon=2&max_offset=3000&id=${results.result[0][0].id}`;
                // const { location } = results.result[0][0];
                // this.handleMap(mapUrl, 7, location, 'map2');
              });
            } else {
              const mapUrl = `https://apis.map.qq.com/ws/district/v1/getchildren?key=KXMBZ-7O3WW-UVYRL-RNUFA-OWYYS-V5BN7&output=jsonp&get_polygon=2&max_offset=3000&id=${results.result[0][0].id}`;
              const { location } = results.result[0][0];
              this.handleMap(mapUrl, 7, location, 'map2');
            }
          },
        });
      }
      if (this.address.length === 2) {
        // console.log('行政区划', this.$refs.cascader.getCheckedNodes()[0].pathLabels);
        req.province_code = `${this.address[0].split(',')[0]}`;
        req.city_code = `${this.address[1].split(',')[0]}`;
        const res = await GetHcoTotalByCity(req);
        // console.log('获取区数据', res);
        // this.data = res.district_data;
        // // 按A-Z排序
        // this.data = this.data.sort(function (a, b) {
        //   return a.district_name.localeCompare(b.district_name, 'zh');
        // });

        let tmp = [];
        tmp = res.district_data;
        // // 按A-Z排序
        // tmp = tmp.sort(function (a, b) {
        //   return a.district_name.localeCompare(b.district_name, 'zh');
        // });

        const importantMap = new Map();
        this.importantData.forEach(item => {
          importantMap.set(item.district_name1, item);
        });
        this.data = tmp.map(item => {
          if (importantMap.has(item.district_name)) {
            return { ...item, ...importantMap.get(item.district_name) };
          }
          return item;
        });

        // this.data.forEach((item) => {
        //   this.totalNum += item.total;
        // });
        const cityReq = {
          districe_level: 3,
          district_code: `${this.address[1].split(',')[0]}`,
          conditions: {
            hco_type_name: '',
            hco_type_key: '',
            hospital_level: this.hospitalLevel,
            business_status: this.businessStatus,
            hco_name: this.name,
          },
        };
        if (this.orgType.length) {
          cityReq.conditions.hco_type_name = `hco_type${this.orgType.length + 1}`;
          cityReq.conditions.hco_type_key = this.orgType[this.orgType.length - 1];
        }
        const cityCover = await GetJurisdictionHcoCounts(cityReq);
        console.log('市覆盖', cityCover);
        const cityCoverMap = new Map();
        cityCover.data.forEach(item => {
          cityCoverMap.set(item.code, item);
        });
        this.data = this.data.map(item => {
          if (cityCoverMap.has(item.district_code)) {
            return {
              ...item,
              cover: cityCoverMap.get(item.district_code).cnt,
              notCover: item.total - cityCoverMap.get(item.district_code).cnt,
              coverage:
                item.total !== 0 ? cityCoverMap.get(item.district_code).cnt / item.total : 0,
            };
          }
          return { ...item, cover: 0, notCover: item.total, coverage: 0 };
        });

        this.data = this.data.sort(function(a, b) {
          return b.coverage - a.coverage;
        });
        console.log('合并后', this.data);
        this.orgCover = 0;
        this.orgNotCover = 0;
        this.totalNum = 0;
        this.data.forEach(item => {
          this.totalNum += item.total;
          this.orgCover += item.cover;
        });
        this.orgNotCover = this.totalNum - this.orgCover;
        this.sortActive = 'down';

        this.areaName = this.address[this.address.length - 1].split(',')[1];
        this.loading = false;

        // const keyWords = this.$refs.cascader.getCheckedNodes()[0].pathLabels[
        //   this.$refs.cascader.getCheckedNodes()[0].pathLabels.length - 1
        // ];
        // const keyWords = name
        let keyWords = '';
        if (name) {
          keyWords = name;
        } else {
          keyWords = this.$refs.cascader.getCheckedNodes()[0]
            ? this.$refs.cascader.getCheckedNodes()[0].pathLabels[
                this.$refs.cascader.getCheckedNodes()[0].pathLabels.length - 1
              ]
            : this.address[this.address.length - 1].split(',')[1];
        }
        console.log('传来的名字', keyWords);
        this.map2Show = false;
        this.map3Show = true;
        const url = `https://apis.map.qq.com/ws/district/v1/search?key=KXMBZ-7O3WW-UVYRL-RNUFA-OWYYS-V5BN7&output=jsonp&get_polygon=2&max_offset=3000&keyword=${keyWords}`;
        window.$.ajax({
          url,
          type: 'GET',
          dataType: 'JSONP',
          success: results => {
            console.log('查到的', results);
            const mapUrl = `https://apis.map.qq.com/ws/district/v1/getchildren?key=KXMBZ-7O3WW-UVYRL-RNUFA-OWYYS-V5BN7&output=jsonp&get_polygon=2&max_offset=3000&id=${results.result[0][0].id}`;
            const { location } = results.result[0][0];
            this.handleMap(mapUrl, 9, location, 'map3');
          },
        });
      }
      if (this.address.length === 3) {
        this.mapLoading = true;
        // console.log('行政区划', this.$refs.cascader.getCheckedNodes()[0].pathLabels);
        const req = {
          condition: {
            hco_type_name: '',
            hco_type_key: '',
            hospital_level: this.hospitalLevel,
            business_status: this.businessStatus,
            hco_name: this.name,
          },
          district_code: `${this.address[2].split(',')[0]}`,
          covered: this.resquestCover,
        };
        if (this.orgType.length) {
          req.condition.hco_type_name = `hco_type${this.orgType.length + 1}`;
          req.condition.hco_type_key = this.orgType[this.orgType.length - 1];
        }
        console.log('参数', req);
        // req.province_code = `${this.address[0].split(',')[0]}`;
        // req.city_code = `${this.address[1].split(',')[0]}`;
        // req.district_code = `${this.address[2].split(',')[0]}`;
        // console.log('参数', req);
        const res = await GetHcoData(req);
        console.log(res.data);
        this.data = res.data;
        this.areaName = this.address[this.address.length - 1].split(',')[1];

        this.orgCover = res.covered_total;
        this.orgNotCover = res.uncovered_total;
        this.totalNum = res.covered_total + res.uncovered_total;
        // console.log(this.data);
        this.loading = false;

        let keyWords = '';
        if (name) {
          keyWords = name;
        } else {
          keyWords = this.$refs.cascader.getCheckedNodes()[0]
            ? this.$refs.cascader.getCheckedNodes()[0].pathLabels[
                this.$refs.cascader.getCheckedNodes()[0].pathLabels.length - 1
              ]
            : this.address[this.address.length - 1].split(',')[1];
        }
        console.log('传来的名字', keyWords);
        this.mapShow = true;
        this.map1Show = false;
        this.map2Show = false;
        this.map3Show = false;
        const url = `https://apis.map.qq.com/ws/district/v1/search?key=KXMBZ-7O3WW-UVYRL-RNUFA-OWYYS-V5BN7&output=jsonp&get_polygon=2&max_offset=3000&keyword=${keyWords}`;
        window.$.ajax({
          url,
          type: 'GET',
          dataType: 'JSONP',
          success: results => {
            // console.log('查到的', results);
            let mapUrl = '';
            let location = {};
            if (results.result[0].length > 1) {
              const matchingAddress = this.address.map(item => item.split(',')[1]);
              // console.log(matching);
              const matching = Array.from(new Set(matchingAddress));
              const filterData = results.result[0].filter(({ address }) => {
                const addressArr = address.split(',');
                return matching.every((v, idx) => {
                  const regExp = new RegExp(addressArr[idx]);
                  return regExp.test(v);
                });
              });
              console.log('匹配出多个区过滤相同的', filterData);
              mapUrl = `https://apis.map.qq.com/ws/district/v1/getchildren?key=KXMBZ-7O3WW-UVYRL-RNUFA-OWYYS-V5BN7&output=jsonp&get_polygon=2&max_offset=3000&id=${filterData[0].id}`;
              location = filterData[0].location;
            } else {
              mapUrl = `https://apis.map.qq.com/ws/district/v1/getchildren?key=KXMBZ-7O3WW-UVYRL-RNUFA-OWYYS-V5BN7&output=jsonp&get_polygon=2&max_offset=3000&id=${results.result[0][0].id}`;
              location = results.result[0][0].location;
            }
            // const { location } = results.result[0][0];
            const map = new window.qq.maps.Map('map', {
              boundary: new window.qq.maps.LatLngBounds(
                new window.qq.maps.LatLng(1, 67),
                new window.qq.maps.LatLng(63, 148),
              ),
              center: new window.qq.maps.LatLng(location.lat, location.lng), // 设置地图中心坐标
              zoom: 16, // 地图进行缩放
              mapStyleId: 'style1', // 个性化样式
              baseMap: {
                // 设置底图样式
                type: 'vector', // 设置底图为矢量底图
                features: [
                  // 设置矢量底图要素类型
                  'base',
                  'point',
                ],
              },
              disableDoubleClickZoom: true,
              scrollwheel: false,
              zoomControlOptions: {
                position: window.qq.maps.ControlPosition.RIGHT_TOP,
              },
              panControlOptions: {
                position: window.qq.maps.ControlPosition.RIGHT_TOP,
              },
            });
            const infoList = [];
            const boundary = []; // 存放属于范围内的点
            const loads = []; // 存放已经加载过的点位ID
            window.qq.maps.event.addListener(
              map,
              'bounds_changed',
              _.debounce(() => {
                const mapBoundary = map.getBounds(); // 得出地图边界范围
                console.log('四个角范围', mapBoundary);
                window.$.ajax({
                  url: mapUrl, // 请求地址+参数
                  type: 'GET', // get请求
                  dataType: 'JSONP', // 指定服务器返回的数据类型
                  success: () => {
                    const iconOptions = {
                      医院: iconList.hospital,
                      经销商: iconList.dealer,
                      基层医疗卫生机构: iconList.jiHospital,
                      专业公共卫生机构: iconList.zHospital,
                      其他医疗卫生机构: iconList.otherHospital,
                      药店: iconList.shop,
                      医疗器械: iconList.instruments,
                      生产企业: iconList.manufacturer,
                      其他分类: iconList.other,
                    };
                    this.data.forEach(item => {
                      if (
                        item.latitude >= mapBoundary.lat.minY &&
                        item.latitude <= mapBoundary.lat.maxY &&
                        item.longitude >= mapBoundary.lng.minX &&
                        item.longitude <= mapBoundary.lng.maxX
                      ) {
                        boundary.push(item);
                      }
                    });
                    console.log('计算出范围内的', boundary);
                    if (!loads.length) {
                      boundary.forEach(item => {
                        loads.push(item.id);
                        const infoWin = new window.qq.maps.InfoWindow({
                          map,
                        });
                        infoWin.setContent(
                          `<div style="width:352px;height:154px;padding:16px;background:#fff">
                                  <p style="color: #1D2129;line-height: 24px;font-size: 16px;font-weight: 500;">${
                                    item.name
                                  }</p>
                                  <div style="display:flex;align-items: center;margin:12px 0">
                                    <div class="tag" style="margin-right:4px;width:8px;height:8px;border-radius:50%;background: ${
                                      this.orgColorOptions[`${item.hco_type_name2}`]
                                    }"></div>
                                    <span style="font-size:14px;line-height:22px;color:#384050;margin-right:24px">${
                                      item.hco_type_name2
                                    }</span>
                                    <div style="min-width:64px;height:20px;background:#E5EAF2;line-height:20px;font-size:12px;color:#1D2129;text-align:center">${
                                      item.hco_type_name3
                                    }</div>
                                  </div>
                                  <p style="margin-bottom:8px;font-size:14px;line-height:22px;color:#667082">${
                                    item.address
                                  }</p>
                                  <p style=font-size:14px;line-height:22px;color:#667082">${
                                    item.latitude
                                  },${item.longitude}</p>
                             </div>`,
                        );
                        infoWin.setPosition(
                          new window.qq.maps.LatLng(item.latitude, item.longitude),
                        );

                        const marker = new window.qq.maps.Marker({
                          position: new window.qq.maps.LatLng(item.latitude, item.longitude),
                          map,
                          icon: iconOptions[`${item.hco_type_name2}`],
                          properties: { content: infoWin },
                        });
                        infoList.push(infoWin);
                        window.qq.maps.event.addListener(marker, 'click', event => {
                          infoList.forEach(win => {
                            win.close();
                          });
                          event.target.properties.content.open();
                        });
                      });
                    } else {
                      console.log('拖动后的加载', loads);
                      boundary.forEach(item => {
                        if (!loads.includes(item.id)) {
                          loads.push(item.id);
                          const infoWin = new window.qq.maps.InfoWindow({
                            map,
                          });
                          infoWin.setContent(
                            `<div style="width:352px;height:154px;padding:16px;background:#fff">
                                  <p style="color: #1D2129;line-height: 24px;font-size: 16px;font-weight: 500;">${
                                    item.name
                                  }</p>
                                  <div style="display:flex;align-items: center;margin:12px 0">
                                    <div class="tag" style="margin-right:4px;width:8px;height:8px;border-radius:50%;background: ${
                                      this.orgColorOptions[`${item.hco_type_name2}`]
                                    }"></div>
                                    <span style="font-size:14px;line-height:22px;color:#384050;margin-right:24px">${
                                      item.hco_type_name2
                                    }</span>
                                    <div style="min-width:64px;height:20px;background:#E5EAF2;line-height:20px;font-size:12px;color:#1D2129;text-align:center">${
                                      item.hco_type_name3
                                    }</div>
                                  </div>
                                  <p style="margin-bottom:8px;font-size:14px;line-height:22px;color:#667082">${
                                    item.address
                                  }</p>
                                  <p style=font-size:14px;line-height:22px;color:#667082">${
                                    item.latitude
                                  },${item.longitude}</p>
                             </div>`,
                          );
                          infoWin.setPosition(
                            new window.qq.maps.LatLng(item.latitude, item.longitude),
                          );

                          const marker = new window.qq.maps.Marker({
                            position: new window.qq.maps.LatLng(item.latitude, item.longitude),
                            map,
                            icon: iconOptions[`${item.hco_type_name2}`],
                            properties: { content: infoWin },
                          });
                          infoList.push(infoWin);
                          window.qq.maps.event.addListener(marker, 'click', event => {
                            infoList.forEach(win => {
                              win.close();
                            });
                            event.target.properties.content.open();
                          });
                        }
                      });
                    }
                    this.mapLoading = false;
                  },
                });
              }, 200),
            );
          },
        });
      }
    },
    // 跳转查看详情
    handleGoDetail(item) {
      const orgType = {
        医院: 'hospital',
        基层医疗卫生机构: 'hospital-ji',
        专业公共医疗卫生机构: 'hospital-pro',
        其他医疗卫生机构: 'hospital-other',
        经销商: 'dealer',
        药店: 'shop',
      };
      const href = this.$router.resolve({
        path: '/dashboard/data-detail',
        query: {
          id: item.id,
          name: item.name,
          type: 'preview',
          source: orgType[item.hco_type_name2],
        },
      });
      window.open(href.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
